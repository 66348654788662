/*
 * 업무구분: 사고보험금 조회
 * 화 면 명: MSPPM812P
 * 화면설명: 사고보험금 조회 > 접수현황 탭(서류추가버튼클릭) > 본인인증
 * 접근권한: 모든사용자
 * 작 성 일: 2023.02.03
 * 작 성 자: 김진원
 */
<template>
  <mo-validate-watcher ref="vWatcher">
    <ur-page-container class="msp" title="본인인증" :show-title="true" type="subpage" :topButton="true"  action-type="none" @on-header-left-click="fn_Pre">
      <!-- 컨텐츠 영역 -->
      <ur-box-container direction="column" alignV="start" class="msp-pm-wrap"> 
        <!-- 본인인증방식 S-->
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-add-area"> 
          <span class="contain-title crTy-bk1 pt15">본인인증</span>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment">
            <mo-segment-wrapper solid primary v-model="authType" @input="fn_ChangeAuthType" class="chip-type-wrap chip-ty1" :disabled="isAuthTypeBtnDisabled">
              <mo-segment-button value="phone">휴대폰인증</mo-segment-button>
              <mo-segment-button value="kakao">카카오인증</mo-segment-button>
            </mo-segment-wrapper>
          </ur-box-container>

          <!-- 휴대폰 인증 -->
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-certify-wrap" v-if="authType === 'phone'">
            <div class="ns-check arrow fex-clumn fs17rem mt20 mb10">
              <mo-checkbox v-model="isCheckAgree" @input="fn_AgreeCnfrm('Open')" :disabled="isAgreeDisabled">본인확인 서비스 이용동의</mo-checkbox>
              <div class="ns-certify-sed">
                <div class="ns-certify-sed-txt">
                  <div class="txt-error crTy-orange2">본인확인 서비스 이용 동의 해주세요.</div>
                </div>
              </div>
            </div>
            <ur-box-container alignV="start" componentid="" direction="column" class="ns-certify-area">
              <ur-box-container alignV="start" componentid="" direction="column" class="info-box">
                <div class="verify-phone msp-verify-phone fexTy5">
                  <msp-bottom-select ref="bottomSelect" :items="telecomTypes" v-model="telecomType" underline class="w100px mr10" placeholder="선택" bottom-title="통신사" :disabled="isTelecomTypeDisabled" closeBtn scrolling/>
                  <span class="fs17rem pl10">{{$props.selfAthntSendData.celno}}</span>
                  <!-- <mo-text-field underline placeholder="000 - 0000 - 0000" class="mb0 txt-center" v-model="mobileNumber" :mask="'phone'" disabled/> -->
                </div>
              </ur-box-container>
              <div class="ns-certify-sed">
                <ur-box-container alignV="start" componentid="ur_box_container_006"  direction="column" class="ns-btn-relative-area mb0 pl0 pr0">
                  <div class="ns-btn-relative-s w100 mb0">
                    <div class="relative-div mb0">
                      <mo-button color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_ReqAuthNumberPhone" :disabled="isReqAuthNumberBtnDisabled">{{reqAuthBtnTitle}}</mo-button>
                    </div>
                  </div>
                </ur-box-container>
              </div>

              <!-- 인증번호 전송 클릭시 표출됨 => 인증확인 완료시 : 클래스값 "success"표시, 인증 오류시: 클래스값 "error"표시 -->
              <div class="ns-certify-sed pt40" :class="authResult" ref="type1" style="display: none;">
                <div class="ns-certify-sed-code mb2sam0">
                  <div class="left">
                    <mo-text-field type="number" class="form-input-name" ref="error" :rules="validateRuleAuthNumber" v-model="authNumber" mask="######" underline placeholder="인증번호를 입력해주세요." :disabled="isAuthNumberDisabled"/>
                    <span class="time">{{countDown}}</span>
                  </div>
                </div>
                <ur-box-container alignV="start" componentid="ur_box_container_006"  direction="column" class="ns-btn-relative-area mb0 pl0 pr0">
                  <div class="ns-btn-relative-s w100 mb0">
                    <div class="relative-div mb0">
                      <mo-button color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_PrcsMoblAthntCnf" :disabled="isAuthConfirmBtnDisabled">인증확인</mo-button>
                    </div>
                  </div>
                </ur-box-container>
                <div class="ns-certify-sed-txt">
                  <div class="txt-success"><span class="ico"></span>인증 완료되었습니다.</div>
                  <div class="txt-error crTy-orange2">인증번호가 다릅니다.</div>
                </div>
              </div>
            </ur-box-container>   
          </ur-box-container>  
          <!-- 휴대폰 인증 //-->

          <!-- 카카오 인증 -->
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-certify-wrap" v-else-if="authType === 'kakao'">
            <div class="ns-check arrow fex-clumn fs17rem mt20 mb10" >
              <mo-checkbox v-model="isCheckAgree" @input="fn_OpenKakaoAgreeDoc" :disabled="isAgreeDisabled">개인정보 이용 활용동의</mo-checkbox>
              <!-- 개인정보 미동의 오류메시지 표출 -->
              <div class="ns-certify-sed" :class="kakaoAgreeDocErrorMsg">
                <div class="ns-certify-sed-txt">
                  <div class="txt-error crTy-orange2">개인정보 이용 및 활용동의 해주세요.</div>
                </div>
              </div>
              <!-- 개인정보 미동의 오류메시지 표출 //-->
            </div>
            <ur-box-container alignV="start" componentid="" direction="column" class="ns-certify-area">
              
              <!--TODO kakaopay 인증에서 통신사 없어도 됨. 없애면 화면 안 깨지나? 확인 해 보자... -->
              <ur-box-container alignV="start" componentid="" direction="column" class="info-box">
                <div class="verify-phone msp-verify-phone fexTy5">
                  <msp-bottom-select ref="bottomSelect" :items="telecomTypes" v-model="telecomType" underline class="w100px mr10" placeholder="선택" bottom-title="통신사" :disabled="isTelecomTypeDisabled" closeBtn scrolling/>
                  <span class="fs17rem pl10">{{$props.selfAthntSendData.celno}}</span>
                </div>
                <!-- 통신사 미선택 오류메시지 표출 -->
                <div class="ns-certify-sed">
                  <div class="ns-certify-sed-txt mt0">
                    <div class="txt-error crTy-orange2">휴대폰 통신사를 선택해주세요</div>
                  </div>
                </div>
                <!-- 통신사 미선택 오류메시지 표출 //-->
              </ur-box-container>
              <!--TODO kakaopay 인증에서 통신사 없어도 됨. -->

              <ur-box-container alignV="start" componentid="ur_box_container_006"  direction="column" class="ns-btn-relative-area mb0 pl0 pr0">
                <div class="ns-btn-relative-s w100 mb0">
                  <div class="relative-div mb0" style="padding-bottom: 10px;">
                    <mo-button color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_ReqAuthNumberKakao" :disabled="isReqAuthNumberBtnDisabled">카카오 인증요청</mo-button>
                  </div>
                  <!--div class="relative-div mb0">
                    <mo-button color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_AuthConfirmKakao" :disabled="isAuthConfirmBtnDisabled">인증결과 확인</mo-button>
                  </div-->
                </div>
              </ur-box-container>
              <!-- 인증요청 클릭시 표출됨 => 인증확인 완료시 : 클래스값 "success"표시, 인증 오류시: 클래스값 "error"표시 -->
              <div class="ns-certify-sed pt10" :class="authResult">
                <div class="ns-certify-sed-txt">
                  <div class="txt-success"><span class="ico"></span>카카오인증이 완료되었습니다.</div>
                  <div class="txt-error crTy-orange2" v-html="authResultMessage"></div>
                </div>
              </div>
            </ur-box-container>   
          </ur-box-container>  
          <!-- 카카오 인증 //-->

          <!-- 하단 안내문구 : 인증번호 미도착시 안내문구 -->
          <ur-box-container alignV="start" componentid="" direction="column" class="text-gray-box bg-none pal0 pt50 pb150">
            <p class="crTy-bk7 pb3">인증번호 미 도착 시 아래와 같이 조치 부탁드립니다.</p>
              <ul class="terms-list-area crTy-bk7 fs14rem">
              <li class="pb3">휴대폰 스팸분류확인</li>
              <li>카카오 고객센터문의 <b class="crTy-blue2 fwn pl5">☎ 1644-7405</b></li>
            </ul>
          </ur-box-container>
          <!-- 하단 안내문구 : 인증번호 미도착시 안내문구 //-->
          
        </ur-box-container>
        <!-- 본인인증방식 E //-->

        <!-- 하단 버튼영역 -->
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative">
          <div class="relative-div">
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_Pre">이전</mo-button>
            <mo-button componentid="mo_button_002" color="normal" shape="border" size="medium" class="ns-btn-round blue" @click="$emit('next')" :disabled="isNextBtnDisabled" >다음</mo-button>
          </div>
        </ur-box-container>
        <!-- 하단 버튼영역 //-->

        <!-- 바텀시트1 : 카카오: 개인정보 이용 활용동의  -->
        <mo-bottom-sheet ref="bottomSheet1" class="ns-bottom-sheet" @before-close="onBeforeClose">
          <template v-slot:title>개인정보 이용 활용 동의</template>
          <!-- <div class="ns-btn-close" @click="close"></div>  -->
          <div class="content-area verify-agree">
            <p class="crTy-bk1 line-h-Ty1">
              (주)카카오는 본인인증서비스 제공을 위해 본인으로부터 아래의 개인정보를 입력받으며, 입력 받은 개인정보의 수집 및 이용을 위해 다음의 사항에
              대하여 본인으로부터 동의를 받습니다.
            </p>
            <div class="text-gray-box mt10">
              <span class="fs14rem pb5">개인(신용)정보 제 3자 제공동의</span>
              <ul class="terms-list-area fs14rem">
                <li class="pb5 crTy-bk7">제공목적:본인인증</li>
                <li class="pb5 crTy-bk7">제공받는자:(주)카카오</li>
                <li class="pb5 crTy-bk7">제공받는자의 개인정보 보유 이용기간 5년</li>
                <li class="pb5 crTy-bk7">제공항목:이름, 생년월일, 휴대폰번호</li>
              </ul>
            </div>
          </div>
          <template v-slot:action>
            <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs noshadow">
              <div class="relative-div">
                <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_ConfirmKakaoAgreeDoc('btnAgree')" name="확인">확인</mo-button>
              </div>
            </ur-box-container>
          </template>
        </mo-bottom-sheet>
        <!-- 바텀시트1 : 카카오: 개인정보 이용 활용동의  -->

        <!-- 이용동의 안내 bottomSheet -->
        <mo-bottom-sheet ref="agreeCnfrmSheet" :close-btn="false" class="ns-bottom-sheet closebtn" @before-close="fn_SheetClose">
          <template v-slot:title>
            본인확인서비스 이용동의
            <div class="ns-btn-close" @click="fn_AgreeCnfrm('CloseBtn')" name="닫기"></div>
          </template>
          <div class="content-area">
            <ul class="terms-list-area pb36">
              <li>개인정보 수집/이용 동의</li>
              <li>고유식별정보 처리 동의</li>
              <li>통신사 이용약관 동의</li>
              <li>서비스 이용약관 동의</li>
              <li>개인정보 제 3자 제공 동의</li>
            </ul>
          </div>

          <template v-slot:action>
            <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative-bs noshadow">
              <div class="relative-div">
                <mo-button componentid="mo_button_005" color="primary" shape="primary" size="border" class="ns-btn-round white" @click="fn_AgreeCnfrm('Confirm')">약관 확인</mo-button>
                <mo-button componentid="mo_button_006" color="primary" shape="primary" size="border" class="ns-btn-round blue" @click="fn_AgreeCnfrm('AllConfirm')">전체 약관 동의</mo-button>
              </div>
            </ur-box-container>
          </template>
        </mo-bottom-sheet>
        <!-- 이용동의 안내 bottomSheet -->

      </ur-box-container>
      <!-- 컨텐츠영역  //-->
    </ur-page-container>
  </mo-validate-watcher>
</template>
<script>
/***********************************************************************************
  * 공통 라이브러리 INCLUDE 영역	                                                   *
  ***********************************************************************************/
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
import Msg from '@/systems/webkit/msg/msg'
import moment from 'moment'

//const COUNTER = '00:10'
const COUNTER = '07:00'
const KCOUNTER = '05:00'

export default {
  /***********************************************************************************
    * Vue 파일 속성 정의 영역	                                                       *
    ***********************************************************************************/
  name: "MSPPM812P",
  screenId: "MSPPM812P",
  components: {},
  // bottom-sheet Full Popup
  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },
  props:{
    selfAthntSendData: {
      type: Object,
      default: {}
    },
    selectedItem: {
      type: Object,
      default: {}
    }
  },

  watch: {
    authType: {
      handler(after, before) {
       if (after === 'kakao') {
          this.fn_AlertPopup()
          this.authType = 'phone'
        }
      },
      deep: true
    }
  },
  /***********************************************************************************
    * Life Cycle 함수 정의 영역	                                                     *
    ***********************************************************************************/
  created() {
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_Pre)
  },

  /** 인스턴스가 Remove 되기 전 호출 */
  beforeDestroy () {
    window.clearInterval(this.timer)
    window.clearInterval(this.interval) // 타이머 종료
    window.vue.getStore('progress').dispatch('HIDE')
    this.authResultMessage = ''

    // backKey event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_Pre)
  },

  mounted() {},

  /***********************************************************************************
    * 화면변수 선언 영역	                                                             *
    ***********************************************************************************/
  data() {
    return {
      validateRuleAuthNumber : [
        (v) => !!v || '인증번호를 입력하세요'
      ],
      //kakaoTestDepth: 2,
      authType: 'phone',
      isContinue: false,
      payload: '', // 카카오알림톡 페이로드
      isCheckAgree : false, // 휴대폰 또는 카카오 이용 동의 체크
      isAgreeDisabled : false, // 휴대폰 또는 카카오 이용 동의 활성 여부
      kakaoAgreeDocErrorMsg : '', // 오류시 error '개인정보 이용 및 활용동의 해주세요.'  메시지 표시
      isTelecomTypeDisabled : false, //통신사 활성 여부
      reqAuthBtnTitle : '인증번호 전송', // 인증번호가 전송되면 '인증번호 재전송' 으로 변경됨.
      authNumber : '', // 인증번호
      authResult : '', // 인증확인 결과 success, error
      authResultMessage : '',
      authSuccessTime: '', // 인증성공시간

      isAuthNumberDisabled : false, // 인증번호 입력란 활성 여부 (인증번호전송 : 활성, 인증확인 : 비활, 인증확인실패 : 활성)

      isAuthTypeBtnDisabled : false, // 인증방식 버튼 활성 여부
      isReqAuthNumberBtnDisabled : false, // 인증번호 전송버튼 활성 여부
      isAuthConfirmBtnDisabled : false, // 인증번호 확인 버튼 활성 여부
      isNextBtnDisabled : true, // 다음 버튼 활성 여부

      countDown : '',
      interval : null, // 인증번호 타이머 인터벌
      timer: null,

      //휴대폰 인증 결과값
      moblAthntCd : '', // 모바일인증코드
      moblAthntPswd : '', // 모바일인증비밀번호
      respUnqNo : '', // 응답고유번호
      reqUnqNo : '', // 요청고유번호
      aprvNoErrCnt : 0, // 인증번호 인증 실패 횟 수

      //카카오인증 결과값
      athntTrnstId: '', //인증트랜잭션ID
      athntHstrId: '', //인증이력ID

      telecomType : 'SKT', // 통신사 선택값
      telecomTypes : [{value: 'SKT',text: 'SKT'}
                    ,{value: 'KT',text: 'KT'}
                    ,{value: 'LGT',text: 'LG U+'}
                    ,{value: 'SKTM',text: 'SKT(알뜰폰)'}
                    ,{value: 'KTM',text: 'KT(알뜰폰)'}
                    ,{value: 'LGTM',text: 'LG U+(알뜰폰)'}] //통신사 select box 목록
    };
  },
  /***********************************************************************************
    * Computed 함수 정의 영역                                                         *
    ***********************************************************************************/
  computed: {},
  /***********************************************************************************
    * 사용자 함수 정의 영역                                                           *
    ***********************************************************************************/
  methods: {
    /******************************************************************************
     * Function명 : onBeforeClose
     * 설명       : 개인정보 이용 및 활용 동의 확인버튼을 제외하곤 닫지 못하도록 설정
     *              확인 버튼 누를 시 item 값 : btnAgree
     *              다른 영역 누를 시 item 값 : swipe
     ******************************************************************************/
    onBeforeClose(fn, item) {
      if (item === 'swipe') return
      fn() // 닫기 실행
    },
    /******************************************************************************
     * Function명 : fn_AlertPopup
     * 설명       : 카카오인증 안내문구 팝업
     ******************************************************************************/
    fn_AlertPopup() {
      let alertPopup = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            single: true,
            title: "서비스일시 중단 안내",
            content: '카카오페이에서 카카오인증으로 변경 되어 서비스 점검 후 재개될 예정입니다. 휴대폰인증으로 접수 진행 부탁드리며, 더 편리하고 안정적인 서비스 제공을 위해 노력하겠습니다.감사합니다.',
            title_pos_btn: "확인"
          },
          listeners: {
            onPopupConfirm: () => {
              this.$bottomModal.close(alertPopup)              
            },
            onPopupCancel: () => {
              this.$bottomModal.close(alertPopup)              
            },
            onPopupClose: () => {
              this.$bottomModal.close(alertPopup)              
            }
          }
        })
    },
    /******************************************************************************
     * Function명 : fn_ChangeAuthType
     * 설명       : 본인인증 방식 변경
     ******************************************************************************/
    fn_ChangeAuthType() {
      
      this.isCheckAgree = false // 휴대폰 또는 카카오 이용 동의 체크
      this.isAgreeDisabled = false // 휴대폰 또는 카카오 이용 동의 활성 여부
      this.isTelecomTypeDisabled = false // 통신사 활성 여부
      this.authNumber = '' // 인증번호
      this.isReqAuthNumberBtnDisabled = false // 인증번호 전송버튼 활성 여부
      this.isNextBtnDisabled = true // 다음 버튼 활성 여부
      this.authSuccessTime = '' // 인증성공시간

      if( this.interval ){
        window.clearInterval(this.interval) //counter 종료
      }

      if( this.timer ){
        window.clearInterval(this.timer) //polling 종료
      }

      // 휴대폰 인증
      if( this.authType === 'phone' ){
        this.countDown = COUNTER
        this.authResult = '' // 인증확인 결과 success, error
        this.reqAuthBtnTitle = '인증번호 전송' // 인증번호가 전송되면 '인증번호 재전송' 으로 변경됨.
        this.isAuthNumberDisabled = false // 인증번호 입력란 활성 여부 (인증번호전송 : 활성, 인증확인 : 비활, 인증확인실패 : 활성)
        this.moblAthntCd = '' // 모바일인증코드
        this.moblAthntPswd = '' // 모바일인증비밀번호
        this.respUnqNo = '' // 응답고유번호
        this.reqUnqNo = '' // 요청고유번호
        this.isAuthConfirmBtnDisabled = false // 인증번호 확인 버튼 활성 여부

      } else {
        this.countDown = KCOUNTER
        this.authResult = 'error' // 인증확인 결과 success, error
        this.authResultMessage = ''
        //this.isAuthConfirmBtnDisabled = true // 인증결과 확인 버튼
      }
    },
    

    /******************************************************************************
     * Function명 : fn_ReqAuthNumberPhone
     * 설명       : 휴대폰 인증번호 요청
     ******************************************************************************/
    fn_ReqAuthNumberPhone() {
      //본인확인 서비스 이용동의 체크 확인
      if(!this.isCheckAgree){
        this.fn_AgreeConfirmOpen()
        return
      }

      if( this.interval ){
        window.clearInterval(this.interval) // 재요청인 경우 기존 타이머 종료
      }
      
      this.$refs.type1.style = "display : block" // 인증번호, 타이머, 인증확인 버튼 display
      this.isAuthNumberDisabled = false // 인증번호 입력필드 비활성화
      this.isAuthConfirmBtnDisabled = false // 인증확인 버튼 비활성화
      this.$refs.vWatcher.clearValidation() // 인증번호 확인 오류 메시지 클리어

      const custInfo = this.$props.selfAthntSendData //고객정보

      let pParams = {
                      custId: this.$bizUtil.isEmpty(custInfo.custId) ? '' : custInfo.custId, // 고객ID
                      custNm: this.$bizUtil.isEmpty(custInfo.custNm) ? '' : custInfo.custNm, // 고객명
                      knbFrno: this.$bizUtil.isEmpty(custInfo.knbFrno) ? '' : custInfo.knbFrno, // 주민번호 앞자리
                      knbBknoCipher: this.$bizUtil.isEmpty(custInfo.knbBknoCipher) ? '' : custInfo.knbBknoCipher, // 주민번호 뒷자리 암호화
                      selfAthntUsScCd: '01', // 본인인증용 구분코드 01 : 개인정보활용동의, 02:전자서명 본인인증(전화번호 수정불가), 03: 본인인증 AS_IS(1:01, 2:02)
                      cmmuScCd: this.telecomType, // 통신사
                      celno: this.$props.selfAthntSendData.celno.replaceAll('-', ''), // 핸드폰 번호
                      knb: 'BU', // 업무구분코드
                      rrn: this.$bizUtil.isEmpty(custInfo.knbBkno) ? '' : (custInfo.knbFrno + custInfo.knbBkno)
                    }

      let lv_Vm = this

      this.post(lv_Vm, pParams, 'txTSSFN70S1', 'S')
        .then(function (response) {

          if (response.body !== null && response.body !== '' && response.body.rsltCd === '0000') {
            
            let lv_TmpResult = response.body

            // 정상요청 된 경우
            if (lv_TmpResult.rsltCd === '0000') {
              lv_Vm.moblAthntCd = lv_TmpResult.moblAthntCd // 모바일인증코드
              lv_Vm.moblAthntPswd = lv_TmpResult.moblAthntPswd // 모바일인증비밀번호
              lv_Vm.respUnqNo = lv_TmpResult.respUnqNo // 응답고유번호
              lv_Vm.reqUnqNo = lv_TmpResult.reqUnqNo // 요청고유번호

              if( lv_Vm.interval !== null ){
                window.clearInterval(lv_Vm.interval)
              }

              lv_Vm.reqAuthBtnTitle = '인증번호 재요청'

              lv_Vm.fn_StartCounter()
            }

          } else { // 정상처리가 아닌경우
            lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
          }

        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },
    


    /******************************************************************************
     * Function명 : fn_PrcsMoblAthntCnf
     * 설명       : 휴대폰 인증번호 확인
     ******************************************************************************/
    fn_PrcsMoblAthntCnf() {
      if(!this.$refs.vWatcher.isValid()){
        return
      } else {
        this.$refs.vWatcher.clearValidation()
      }

      this.aprvNoErrCnt++ // 인증번호 전송 횟수 체크 (3회까지만 허용됨)

      const custInfo = this.$props.selfAthntSendData //고객정보

      let pParams = {
                      custId: this.$bizUtil.isEmpty(custInfo.custId) ? '' : custInfo.custId, // 고객ID
                      custNm: this.$bizUtil.isEmpty(custInfo.custNm) ? '' : custInfo.custNm, // 고객명
                      knbFrno: this.$bizUtil.isEmpty(custInfo.knbFrno) ? '' : custInfo.knbFrno, // 주민번호 앞자리
                      knbBknoCipher: this.$bizUtil.isEmpty(custInfo.knbBknoCipher) ? '' : custInfo.knbBknoCipher, // 주민번호 뒷자리 암호화
                      selfAthntUsScCd: '01', // 본인인증용 구분코드 01 : 개인정보활용동의, 02:전자서명 본인인증(전화번호 수정불가), 03: 본인인증 AS_IS(1:01, 2:02)
                      celno: this.$props.selfAthntSendData.celno.replaceAll('-', ''), // 핸드폰 번호
                      knb: 'NB', // 업무구분코드
                      rrn: this.$bizUtil.isEmpty(custInfo.knbBkno) ? '' : (custInfo.knbFrno + custInfo.knbBkno),
                      moblAthntCd: this.moblAthntCd, // 모바일인증코드
                      moblAthntPswd: this.moblAthntPswd, // 모바일인증비밀번호
                      reqUnqNo: this.reqUnqNo, // 요청고유번호
                      respUnqNo: this.respUnqNo, // 응답고유번호
                      athntNo: this.authNumber, // 인증번호
                      aprvNoErrCnt: this.aprvNoErrCnt // 인증번호 오류 횟수
                    }

      let lv_Vm = this

      this.post(lv_Vm, pParams, 'txTSSFN70S2', 'S')
        .then(function (response) {

          if (response.body !== null && response.body !== '' && response.body.rsltCd === '0000') {

            let lv_TmpResult = response.body

            if (lv_TmpResult) {
              lv_Vm.isAgreeDisabled = true //본인확인 서비스 이용동의 체크박스 비활성화
              lv_Vm.isTelecomTypeDisabled = true //통신사 비활성화

              // timer init
              window.clearInterval(lv_Vm.interval) // 타이머 종료
              
              //req auth btn init
              lv_Vm.reqAuthBtnTitle = '인증번호 요청'
              lv_Vm.isReqAuthNumberBtnDisabled = true // 인증번호 요청 버튼 비활성화
              
              //auth number input
              //lv_Vm.authNumber = '' // 인증번호 초기화
              lv_Vm.isAuthNumberDisabled = true // 인증번호 input 비활성화

              //auth confirm btn init
              lv_Vm.isAuthTypeBtnDisabled = true // 인증방식 버튼 비활성화
              lv_Vm.isAuthConfirmBtnDisabled = true // 인증확인 버튼 비활성화
              lv_Vm.authResult = 'success'
              lv_Vm.isNextBtnDisabled = false // 다음 버튼 활성화

              // 인증 성공 시간 조회(서버기준)
              lv_Vm.$commonUtil.getServerTime().then(function (response) {
                lv_Vm.authSuccessTime = moment(response.toString()).format('YYYY-MM-DD HH:mm')
              })
            }
            
          } else { // 정상처리가 아닌경우
            lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)

            if (response.msgComm.msgCd === 'ECMU001') { // 인증번호 3회 오류 시
              lv_Vm.authResult = 'error'

              // init timer
              lv_Vm.countDown = COUNTER
              lv_Vm.$refs.vWatcher.clearValidation()
              lv_Vm.authNumber = '' // 인증번호 값 초기화
              lv_Vm.isAuthNumberDisabled = true // 인증번호 입력필드 비활성화
              lv_Vm.isAuthConfirmBtnDisabled = true // 인증확인 버튼 비활성화
              window.clearInterval(lv_Vm.interval) // 타이머 종료

            }
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },




    /******************************************************************************
     * Function명 : fn_StartCounter
     * 설명       : 인증 타이머 카운트다운
     ******************************************************************************/
    fn_StartCounter(){
      this.countDown = COUNTER

      let min = Number(COUNTER.split(':')[0])
      let sec = Number(COUNTER.split(':')[1])

      let lv_Vm = this

      // 인증 count down 시작
      this.interval = window.setInterval(function(count){
        count.setTime(count-1000) //interval clear 시 count 초기화
        
        let m = count.getMinutes() //type int
        let s = count.getSeconds() //type int

        if( m+s > 0  ){
          let min = String(m).padLeft(2, '0') //type string
          let sec = String(s).padLeft(2, '0') //type string

          lv_Vm.countDown =  min.concat(':', sec) // 07:00

        }
        // 시간 초과
        else {

          // init
          lv_Vm.$refs.vWatcher.clearValidation()
          lv_Vm.countDown = COUNTER
          lv_Vm.authNumber = '' // 인증번호 값 초기화
          lv_Vm.isAuthNumberDisabled = true // 인증번호 입력필드 비활성화
          lv_Vm.isAuthConfirmBtnDisabled = true // 인증확인 버튼 비활성화

          window.clearInterval(lv_Vm.interval) // 타이머 종료

        }
      }, 1000, new Date(0, 0, 0, 0, min, sec))

    },

    fn_setError(){
      window.clearInterval(this.timer)
      window.clearInterval(this.interval) // 타이머 종료
      window.vue.getStore('progress').dispatch('HIDE')

      this.authResultMessage = '인증 오류'
    },

    /******************************************************************************
     * Function명 : fn_StartKakaoCounter
     * 설명       : 인증 타이머 카운트다운
     ******************************************************************************/
    fn_StartKakaoCounter(){
      this.countDown = KCOUNTER
      this.authResultMessage = '인증번호 만료까지 ' + this.countDown

      let min = Number(KCOUNTER.split(':')[0])
      let sec = Number(KCOUNTER.split(':')[1])

      let lv_Vm = this

      // 인증 count down 시작
      this.interval = window.setInterval(function(count){
        count.setTime(count-1000) //interval clear 시 count 초기화
        
        let m = count.getMinutes() //type int
        let s = count.getSeconds() //type int

        lv_Vm.isContinue = m+s > 0

        if( lv_Vm.isContinue  ){
          let min = String(m).padLeft(2, '0') //type string
          let sec = String(s).padLeft(2, '0') //type string

          lv_Vm.countDown =  min.concat(':', sec) // 07:00
          lv_Vm.authResultMessage = '인증번호 만료까지 ' + lv_Vm.countDown

        }
        // 시간 초과
        else {

          // init
          lv_Vm.countDown = KCOUNTER
          lv_Vm.authResultMessage = ''
          window.clearInterval(lv_Vm.interval) // 타이머 종료

        }
      }, 1000, new Date(0, 0, 0, 0, min, sec))

    },

    /******************************************************************************
     * Function명 : fn_AgreeConfirmOpen
     * 설명       : 개인정보 이용 활용동의 confirm창
     ******************************************************************************/
    fn_AgreeConfirmOpen() {
      const lv_Vm = this

      let lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            title: '※ 알림 : 본인확인 서비스 이용 동의',
            content: '본인확인 서비스 이용 동의시 인증번호가 전송됩니다.<br>이용에 동의하시겠습니까?',
            title_pos_btn: '예',
            title_neg_btn: '아니오'
          },
          listeners: {
            onPopupConfirm: () => {
              this.$bottomModal.close(lv_AlertPop)
              lv_Vm.isCheckAgree = true
              lv_Vm.fn_AgreeCnfrm('Open')
            },
            onPopupClose: () => {
              this.$bottomModal.close(lv_AlertPop)
            }
          }
        })
    },



    /******************************************************************************
     * Function명 : fn_PreConfirmOpen
     * 설명       : 이전화면 이동 confirm창
     ******************************************************************************/
    fn_PreConfirmOpen() {
      let lv_Vm = this
      let lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            title: '※ 알림 : 본인 인증 초기화',
            content: '이전 화면으로 이동 시, 본인 인증이 초기화 됩니다.<br>이동 하시겠습니까?',
            title_pos_btn: '예',
            title_neg_btn: '아니오'
          },
          listeners: {
            onPopupConfirm: () => {
              lv_Vm.$bottomModal.close(lv_AlertPop)
              lv_Vm.$emit('pre')
            },
            onPopupClose: () => {
              lv_Vm.$bottomModal.close(lv_AlertPop)
            }
          }
        })
    },


    /******************************************************************************
     * Function명 : fn_ReqAuthNumberKakao
     * 설명       : 카카오Pay 인증 전송
     ******************************************************************************/
    fn_ReqAuthNumberKakao() {

      // 동의 체크 확인
      if( !this.isCheckAgree ){
        this.kakaoAgreeDocErrorMsg = 'error'
        return

      }

      const custInfo = this.$props.selfAthntSendData
      this.kakaoAgreeDocErrorMsg = '' // error 메시지 초기화
      this.authResult = 'error' // 인증 요청 및 결과

      let params ={
                    name: custInfo.custNm,
                    birthday: this.$bizUtil.addBirthFrontYear(custInfo.knbFrno),
                    phoneNo: custInfo.celno, // 전화번호
                  }

      window.vue.getStore('progress').dispatch('FULL')
      window.vue.getStore('progress').dispatch('SHOW')

      let lv_Vm = this
      this.post(this, params, 'txTSSBU02S2', 'S', undefined, true)
        .then(function (response) {
          if (response.body !== null && response.body !== '') {
            let rtnData = response.body
            if (rtnData.result === '00') {
              lv_Vm.payload = rtnData.payload
              lv_Vm.fn_StartKakaoCounter()
              lv_Vm.fn_kakaoStep2()
              lv_Vm.getStore('toast').dispatch('ADD', '[1/4] 카카오 인증요청 하였습니다.')
            }
          } else {
            lv_Vm.getStore('confirm').dispatch('SETCOLOR', 'purple')
            lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
            window.vue.getStore('progress').dispatch('HIDE')
          }

        })
        .catch(function (error) {
          window.vue.error(error)
          lv_Vm.fn_setError()
        })
    },


    /******************************************************************************
    * Function명 : 카카오 2단계 수행
    * 설명       : 카카오 2단계 수행 / 전자서명 상태 조회
    ******************************************************************************/
    fn_kakaoStep2 () {

      let lv_Vm = this
      let pollingCnt = 0 //5초마다 polling 용도

      this.timer = setInterval(() => {
        if( lv_Vm.isContinue === false ){
          lv_Vm.getStore('confirm').dispatch('SETCOLOR', 'purple')
          lv_Vm.getStore('confirm').dispatch('ADD', '본인인증시간이 종료되었습니다.')
          lv_Vm.fn_setError()
          return
        }

        if (++pollingCnt % 5 === 0) {
          let param = {
            payload: lv_Vm.payload
          }

          lv_Vm.post(lv_Vm, param, 'txTSSBC35P3', 'S', undefined, true)
            .then(function (response) {
              // 진행바 표시
              if (response.body !== null && response.body !== '') {
                let rtnData = response.body

                //if( lv_Vm.kakaoTestDepth > 2 ){
                //  rtnData.status = 'COMPLETE'
                //}

                console.log('#### 2단계 : ' + rtnData.status )
                if (rtnData.status !== null && rtnData.status === 'COMPLETE') {
                  lv_Vm.getStore('toast').dispatch('ADD', '[2/4] 서명 완료 되었습니다.')
                  // COMPLETE : 서명완료
                  console.log('####### 3단계 이동')
                  lv_Vm.fn_kakaoStep3()

                } else if (rtnData.status !== null && rtnData.status === 'EXPIRED') {
                  // EXPIRED : 타임아웃
                  lv_Vm.getStore('confirm').dispatch('SETCOLOR', 'purple')
                  lv_Vm.getStore('confirm').dispatch('ADD', '본인인증시간이 종료되었습니다.')
                  lv_Vm.fn_setError()
                }
              } else {

                // 정상처리가 아닌경우
                let lv_errorMsg = ''
                let lv_errorCd = response.msgComm.msgCd
                if (lv_errorCd === 'E400') {
                  lv_errorMsg = '(카카오톡 미사용/해지 고객)'
                } else if ( lv_errorCd === 'E401' ) {
                  lv_errorMsg = '(휴대폰 번호 누락/불일치)'
                } else if ( lv_errorCd === 'E402' ) {
                  lv_errorMsg = '(고객명 누락/불일치)'
                } else if ( lv_errorCd === 'E403' ) {
                  lv_errorMsg = '(생년월일 누락/불일치)'
                } else if ( lv_errorCd === 'E421' ) {
                  lv_errorMsg = '(생년월일 불일치)'
                } else if ( lv_errorCd === 'E422' ) {
                  lv_errorMsg = '(고객명 불일치)'
                } else {
                  lv_errorMsg = '\n'
                }
                let reqMsg = '카카오Pay 인증이 발송되지 않았습니다.\n' + lv_errorMsg + '\n자세한 사항에 대한 문의는 카카오Pay\n고객센터로 문의 바랍니다.'
                lv_Vm.getStore('confirm').dispatch('SETCOLOR', 'purple')
                lv_Vm.getStore('confirm').dispatch('ADD', reqMsg)
                lv_Vm.fn_setError()
              }
            })
            .catch(function (error) {
              window.vue.error(error)
              lv_Vm.fn_setError()
            })
        }
      }, 1000)
    },
    /******************************************************************************
    * Function명 : 카카오 3단계 수행
    * 설명       : 카카오 3단계 수행 / 전자서명 검증
    ******************************************************************************/
    fn_kakaoStep3 () {

      window.clearInterval(this.timer)

      let lv_Vm = this
      let param = {
        payload : this.payload
      }

      this.post(this, param, 'txTSSBC35P5', 'S', undefined, true)
        .then(function (response) {

          if (response.body !== null && response.body !== '') {

            let rtnData = response.body

            //if( lv_Vm.kakaoTestDepth > 3 ){
            //  rtnData.status = 'COMPLETE'
            //}

            console.log('#### 3단계 : ' + rtnData.status )
            if (rtnData.status !== null && rtnData.status === 'COMPLETE') {
              lv_Vm.getStore('toast').dispatch('ADD', '[3/4] 카카오 검증 발신 되었습니다.')
              // COMPLETE : 서명완료
              console.log('#### 4단계 이동' )
              lv_Vm.fn_kakaoStep4()

            } else {
              lv_Vm.getStore('confirm').dispatch('SETCOLOR', 'purple')
              lv_Vm.getStore('confirm').dispatch('ADD', '본인인증시 오류가 발생하였습니다.')
              lv_Vm.fn_setError()
            }

          } else {
            lv_Vm.getStore('confirm').dispatch('SETCOLOR', 'purple')
            lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
            lv_Vm.fn_setError()

          }
        })
        .catch(function (error) {
          window.vue.error(error)
          lv_Vm.fn_setError()
        })
    },
    /******************************************************************************
    * Function명 : 카카오 4단계 수행
    * 설명       : 카카오 4단계 수행 / 전자서명 상태조회 (카카오톡인증서명검증일시 적용)
    ******************************************************************************/
    fn_kakaoStep4 () {
      window.clearInterval(this.timer)

      let pollingCnt = 0 //5초마다 polling 용도
      let lv_Vm = this

      this.timer = setInterval(() => {
        if( lv_Vm.isContinue === false ){
          lv_Vm.getStore('confirm').dispatch('ADD', '본인인증시간이 종료되었습니다.')
          lv_Vm.fn_setError()
          return
        }

        if (++pollingCnt % 5 === 0) {
          let param = {
            payload : lv_Vm.payload,
            isSign : 'N' // 서명여부
          }

          lv_Vm.post(lv_Vm, param, 'txTSSBC35S7', 'S', undefined, true)
            .then(function (response) {
              // 진행바 표시
              if (response.body !== null && response.body !== '') {
                let rtnData = response.body

                //if( lv_Vm.kakaoTestDepth === 4 ){
                //  rtnData.isSignedData = true
                //  rtnData.status = 'COMPLETE'
                //}

                console.log('#### 4단계 : ' + rtnData.status + ' / ' + rtnData.isSignedData)
                if (rtnData.status !== null && rtnData.status === 'COMPLETE' && rtnData.isSignedData) {
                  lv_Vm.getStore('toast').dispatch('ADD', '[4/4] 카카오 검증 되었습니다.')
                  
                  window.clearInterval(lv_Vm.timer)
                  window.clearInterval(lv_Vm.interval) // 타이머 종료
                  
                  // 인증 성공 시간 조회(서버기준)
                  lv_Vm.$commonUtil.getServerTime().then(function (response) {
                    lv_Vm.authSuccessTime = moment(response.toString()).format('YYYY-MM-DD HH:mm')
                  })

                  lv_Vm.isAgreeDisabled = true //본인확인 서비스 이용동의 체크박스 비활성화
                  lv_Vm.isReqAuthNumberBtnDisabled = true // 인증요청 버튼 비활성화
                  lv_Vm.isTelecomTypeDisabled = true //통신사 비활성화
                  lv_Vm.isAuthTypeBtnDisabled = true // 인증 방식 버튼 비 활성화
                  lv_Vm.isNextBtnDisabled = false // 다음 버튼 활성화
                  lv_Vm.authResult = 'success' // 인증 완료 메시지 출력
                  window.vue.getStore('progress').dispatch('HIDE')

                }
              } else {
                lv_Vm.getStore('confirm').dispatch('SETCOLOR', 'purple')
                lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
                lv_Vm.fn_setError()
              }
            })
            .catch(function (error) {
              window.vue.error(error)
              lv_Vm.fn_setError()
            })
        }
      }, 1000)
    },


    /******************************************************************************
     * Function명 : fn_AuthConfirmKakao
     * 설명       : 인증결과 확인 전송
     ******************************************************************************//*
    fn_AuthConfirmKakao() {
      let params = {
                     athntTrnstId: this.athntTrnstId, // 트랜잭션id
                     athntHstrId: this.athntHstrId
                   }

      let lv_Vm = this
      this.post(lv_Vm, params, 'txTSSCM12S3', 'S')
        .then(function (response) {

          //local 환경에서는 그냥 패스
          if( process.env.NODE_ENV === 'local' ){
            response.body = {}
            response.body.athntStatRespCd = 'P000'
            response.body.athntTrtStatCd = 'COMPLETE'
          }

          if (response.body !== null && response.body !== '' && response.body.athntStatRespCd === 'P000') {

            if ( response.body.athntTrtStatCd === 'COMPLETE' ) { // 서명완료
              lv_Vm.isAgreeDisabled = true //본인확인 서비스 이용동의 체크박스 비활성화
              lv_Vm.isTelecomTypeDisabled = true //통신사 비활성화

              //req auth btn init
              lv_Vm.isReqAuthNumberBtnDisabled = true // 인증요청 버튼 비활성화
              
              //auth confirm btn init
              lv_Vm.isAuthConfirmBtnDisabled = true // 인증결과 확인 버튼 비활성화

            } else if ( response.body.athntTrtStatCd === 'PREPARE' ) {
              lv_Vm.authResultMessage = '카카오Pay 인증이 완료되지 않았습니다.<br>인증 완료후 ‘인증결과 확인’을 다시 한 번 클릭하시기 바랍니다.'
            } else if ( response.body.athntTrtStatCd === 'EXPIRED' ) { // 타임아웃
              lv_Vm.authResultMessage = '인증시간이 초과되었습니다.<br>상단의 ‘카카오 인증요청’버튼을 눌러 재인증 하시기 바랍니다.'
            }

          } else { // 정상처리가 아닌경우
            if (response.body.athntReqRespCd === 'E400') {
              response.msgComm.msgDesc = '중복 호출. 잘못된 요청입니다.'
            } else if ( response.body.athntReqRespCd === 'E401' ) {
              response.msgComm.msgDesc = '조회 식별자가 존재하지 않습니다.'
            } else if ( response.body.athntReqRespCd === 'E402' ) {
              response.msgComm.msgDesc = '서명 결과가 존재하지 않습니다.'
            } else if ( response.body.athntReqRespCd === 'E403' ) {
              response.msgComm.msgDesc = '서명이 완료되지 않았습니다.'
            } else if ( response.body.athntReqRespCd === 'E404' ) {
              response.msgComm.msgDesc = 'Transaction ID가 존재하지 않습니다.'
            } else if ( response.body.athntReqRespCd === 'E405' ) {
              response.msgComm.msgDesc = '이용기관 정보를 찾을 수 없습니다.'
            } else {
              response.msgComm.msgDesc = ''
            }
            lv_Vm.authResultMessage = '카카오Pay 인증이 실패되었습니다.<br>' + response.msgComm.msgDesc
          }

          // 인증 성공
          if( lv_Vm.$bizUtil.isEmpty(lv_Vm.authResultMessage)) {
            // 인증 성공 시간 조회(서버기준)
            lv_Vm.$commonUtil.getServerTime().then(function (response) {
              lv_Vm.authSuccessTime = moment(response.toString()).format('YYYY-MM-DD HH:mm')
            })

            lv_Vm.isAuthTypeBtnDisabled = true // 인증 방석 버튼 비 활성화
            lv_Vm.isNextBtnDisabled = false // 다음 버튼 활성화
            lv_Vm.authResult = 'success' // 인증 완료 메시지 출력
          }
          // 인증 실패
          else {
            lv_Vm.authResult = 'error' // 인증 실패 메시지 출력
          }
          
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },*/



    /******************************************************************************
     * Function명 : fn_OpenKakaoAgreeDoc
     * 설명       : 카카오 인증 안내
     ******************************************************************************/
    fn_OpenKakaoAgreeDoc(isChecked){
      if( isChecked ){
        this.$refs.bottomSheet1.open()
      }

      //isCheckAgree 변수가 checkbox 에 bind 된 변수이긴 한데, 체크 했을 때 개인정보 이용 활용 동의 팝업에 있는 확인 버튼을 눌렀을 때 체크 되도록 하기 위해 false 셋팅.
      this.isCheckAgree = false

    },


    
    /******************************************************************************
     * Function명 : fn_ConfirmKakaoAgreeDoc
     * 설명       : 개인정보 이용 및 활용 동의 확인 버튼
     ******************************************************************************/
    fn_ConfirmKakaoAgreeDoc(v) {
      this.isCheckAgree = true
      this.kakaoAgreeDocErrorMsg = ''
      this.$refs.bottomSheet1.close(v)
    },


    /**
     * @name fn_Pre
     * @description 이전 버튼 클릭
     */
    fn_Pre(){
      if( this.authResult === 'success' ){
        this.fn_PreConfirmOpen()
      } else {
        this.$emit('pre')
      }
    },


    /**
     * @name isNotEmpty
     * @description 개발 편의
     * @param {Object|String} value
     */
    isNotEmpty(value){
      return !this.$bizUtil.isEmpty(value)
    },

    /******************************************************************************
    * Function명 : fn_AgreeCnfrm
    * 설명       : 본인확인서비스 이용동의 안내 
    *           - OPEN(컨펌팝업열기), Cancel(창닫기), Confirm(창닫기) 선택에 대한 화면 이동
    ******************************************************************************/
    fn_AgreeCnfrm(param) {
      switch (param) {
        case 'Open':
          if(this.isCheckAgree){
            this.isCheckAgree = false
            this.$refs.agreeCnfrmSheet.open()
          }
          break
        case 'CloseBtn':
          this.$refs.agreeCnfrmSheet.close()
          break
        case 'Confirm': // 약관 확인
          this.$refs.agreeCnfrmSheet.close('S')
          this.fn_AgreeConfirmYes() // 동의 나이스 링크 호출
          break
        case 'AllConfirm': // 전체 약관 확인
          this.$refs.agreeCnfrmSheet.close('S')
          break
      }
    },

    /******************************************************************************
    * Function명 : fn_AgreeConfirmYes
    * 설명       : 본인확인 서비스 이용 안내 confirm팝업창의 '동의' / '동의안함' 버튼 선택 시
    ******************************************************************************/
    fn_AgreeConfirmYes () {
      this.isCheckAgree = true
      this.fn_AgreePopContent()
    },

    /******************************************************************************
    * Function명 : fn_AgreePopContent
    * 설명       : 이통사 본인확인 서비스 이용에 따른 동의 나이스 링크 호출
    ******************************************************************************/
    fn_AgreePopContent () {
      if (this.$commonUtil.isMobile()) { // 모바일에서 작동
        window.fdpbridge.exec('intentBrowserPlugin', {uri: this.sCertVnoUrl}, () => {}, () => {})
      } else { // PC에서 작동
        window.open(this.sCertVnoUrl, '_blank')
      }
    },

    /******************************************************************************
    * Function명 : fn_SheetClose
    * 설명       : 본인확인서비스 이용동의 안내 Close Fn
    *              - 약관동의로 Close / 강제로 Close 구분 목적
    * @param  fn: BottomSheet Close Function
    * @param  item: BottomSheet Event Name
    * @notice 강제로 / X버튼으로 닫을시 Event Name = 'swipe'
    *         - 약관 동의하게되면 item = 'S' 
    ******************************************************************************/
    fn_SheetClose(fn_CloseSheet, item) {
      this.isCheckAgree = item === 'swipe' ? false : true
      fn_CloseSheet()
    },
  } // ::Methods End
};
</script>